var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('loading'):(_vm.isError)?_c('error'):(!_vm.subPermission.index)?_c('content-not-view'):_c('b-card',[_c('div',{staticClass:"custom-search d-flex justify-content-between mb-1"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('label',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t("g.searchLabel")))]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":_vm.$t('g.searchHere'),"type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])]),(_vm.subPermission.store)?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-button',{attrs:{"to":{ name: 'AddClient' },"variant":"primary"}},[_vm._v(_vm._s(_vm.$t("g.addClient")))])],1):_vm._e()],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.direction,"styleClass":"vgt-table condensed","search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm,
      },"select-options":{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label === 'name')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.name"))+" ")]):(props.column.label === 'email')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.email"))+" ")]):(props.column.label === 'country')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.country"))+" ")]):(props.column.label === 'phoneNumber')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.phoneNumber"))+" ")]):(props.column.label === 'action')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.action"))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',[(_vm.subPermission.show)?_c('router-link',{attrs:{"to":{ name: 'ViewClient', params: { id: props.row.id } }}},[_vm._v(" "+_vm._s(props.row.name)+" ")]):_c('h5',[_vm._v(" "+_vm._s(props.row.name)+" ")])],1):(props.column.field === 'action')?_c('span',[_c('span',{staticClass:"d-flex align-items-right"},[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":"","text":"Block Level Dropdown Menu","block":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(_vm.subPermission.update)?_c('b-dropdown-item',{attrs:{"to":{
                  name: 'EditClient',
                  params: { id: props.row.id },
                }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.edit")))])],1):_vm._e(),(_vm.subPermission.destroy)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteAlert(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Trash2Icon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.delete")))])],1):_vm._e()],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.pagelength"))+" ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['5', '10', '20']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.of"))+" "+_vm._s(props.total)+" "+_vm._s(_vm.$t("g.pageText"))+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }